<template>
  <div class="d-flex flex-row">
    <v-col 
      md="11"
      sm="11"
      :cols="$vssWidth < 600 ? 10 : 8"
    >
      <v-card
        class="pa-2"
        flat
        color="transparent"
      >
        <v-expansion-panels
          tile
          focusable
          hover
          popout
          flat
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                Instituição: {{ obj.fantas }}
              </div> 
              <div class="d-flex align-end flex-column">
                {{ obj.inst }}
              </div>
            </v-expansion-panel-header>
        
       
            <v-expansion-panel-content          
              :style="{height: '260px'}"
            >
              <v-row
                class="d-flex flex-nowrap"
                no-gutters
                :style="{height: '100%', overflowX: 'auto !important'}"
              >
                <v-col
                  v-for="loja, index in arr"
                  :key="index"
                  sm="6"
                  md="3"
                  cols="12"
                  class="pa-2"
                >
                  <ParamLojas
                    v-model="dialog"                     
                    :dados-lojas="obj"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card> 
    </v-col> 
    <v-col
      md="1"
      sm="1"
      :cols="$vssWidth < 600 ? 1 : 8"
      class="d-flex align-center"
    >
      <ParamGerais 
        v-model="dialogParamsGerais"
      />
    </v-col>
  </div>
</template>

<script>
import ParamLojas from './ParamLojas.vue'
import ParamGerais from './ParamGerais.vue'
export default {
  components: {
    ParamLojas,    
    ParamGerais,
  },
  
  data() {
    return {          
      dialogParamsGerais: false,
      paramLojas: false,
      arr: [1,2,3,4,5,6,7,8,9,10],
      obj: {
        fantas: "SaaSTec Labs",
        razao: "SaaSTec Network",
        endereco: "Martin Afonso, 1855",
        numLoja: '1-9',
        inst: '116',
      },      
    }
  },
}

</script>

<style>

</style>
