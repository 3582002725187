var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"85%","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-card',_vm._g(_vm._b({style:({ backgroundColor: 'var(--v-primary-lighten1)', height: '100%' }),attrs:{"flat":""}},'v-card',attrs,false),on),[_c('v-card-title',{staticClass:"pt-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.dadosLojas.fantas)+" "),_c('v-spacer'),_c('span',{style:({ fontSize: '16px' })},[_vm._v(_vm._s(_vm.dadosLojas.numLoja))])],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"transicoes",attrs:{"height":"90vh"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-toolbar',{staticClass:"primary white--text text-h5",attrs:{"flat":"","dense":""}},[_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.screen),expression:"screen"}],attrs:{"icon":"","dark":""},on:{"click":_vm.backTreeView}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("arrow_back")])],1)]}}])},[_c('span',[_vm._v(" Voltar ")])]),_vm._v(" Parâmetros por loja "),_c('v-btn',{attrs:{"absolute":"","right":"","icon":"","dark":""},on:{"click":function($event){_vm.paramLojas = !_vm.paramLojas}}},[_c('v-icon',[_vm._v("close")])],1)],1)],1),_c('v-row',{staticClass:"pl-1 pt-1 pr-1",style:({ height: '92% !important' }),attrs:{"no-gutters":""}},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.treeView || _vm.$vssWidth > 960),expression:"treeView || $vssWidth > 960"}],style:({ maxHeight: '100%', overflowY: 'auto' }),attrs:{"sm":"12","md":"4","cols":"12"}},[_c('v-treeview',{style:({ userSelect: 'none' }),attrs:{"items":_vm.items,"activatable":"","hoverable":"","open-all":"","transition":"","rounded":"","item-key":"path"},on:{"update:active":_vm.escolhePagina}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.screen || _vm.$vssWidth > 960),expression:"screen || $vssWidth > 960"}],staticClass:"pa-3",style:({ borderLeft: '1px solid #ccc', maxHeight: '100%' }),attrs:{"sm":"12","md":"8","cols":"12"}},[_c('v-row',{staticClass:"pa-3",style:({ height: '90%', border: '1px solid var(--v-primary-lighten3)', borderRadius: '5px', maxHeight: '100%', overflowY: 'auto ' }),attrs:{"no-gutters":""}},[_c(_vm.component,{key:_vm.attComp,tag:"component",attrs:{"title":_vm.titulo}})],1),_c('v-row',{key:_vm.treeView,staticClass:"d-flex justify-end align-center",style:({ height: '10%' }),attrs:{"no-gutters":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btnEdit mr-2",attrs:{"depressed":"","color":"transparent"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(" edit ")]),(_vm.treeView || _vm.$vssWidth > 600)?_c('span',[_vm._v(" Editar ")]):_vm._e()],1)]}}])},[_c('span',[_vm._v(" Editar ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btnSave mr-2",attrs:{"depressed":"","color":"transparent"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green darken-1"}},[_vm._v(" save ")]),(_vm.treeView || _vm.$vssWidth > 600)?_c('span',[_vm._v(" Salvar ")]):_vm._e()],1)]}}])},[_c('span',[_vm._v(" Salvar ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btnCancel",attrs:{"depressed":"","color":"transparent"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v(" cancel ")]),(_vm.treeView || _vm.$vssWidth > 600)?_c('span',[_vm._v(" cancelar ")]):_vm._e()],1)]}}])},[_c('span',[_vm._v(" Cancelar ")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }