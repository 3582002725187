<template>
  <v-dialog
    v-model="dialog"
    width="85%"
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template #activator="{attrs, on}">
      <v-btn
        v-bind="attrs"
        class="ma-2"
        color="primary"
        icon
        large
        depressed
        v-on="on"
        @click="dialog = !dialog"
      >
        <v-icon dark>
          settings
        </v-icon>
      </v-btn>
    </template>
    <v-card
      height="90vh"
      class="transicoes"
    >
      <v-row no-gutters>
        <v-toolbar
          class="primary white--text text-h5"
          flat
          dense
        >
          <v-tooltip>
            <template #activator="{attrs, on}">
              <v-btn
                v-show="screen"
                icon
                dark
                v-bind="attrs"
                v-on="on"
                @click="backTreeView"
              >
                <v-icon>arrow_back</v-icon>
              </v-btn>
            </template>
            <span>
              Voltar
            </span>
          </v-tooltip>

          Parâmetros Gerais
          <v-btn
            absolute
            right
            icon
            dark
            @click="dialog = !dialog"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-row>
      <v-row
        no-gutters
        :style="{ height: '92% !important' }"
        class="pl-1 pt-1 pr-1"
      >
        <v-col
          v-show="treeView || $vssWidth > 960"
          sm="12"
          md="4"
          cols="12"
          :style="{ maxHeight: '100%', overflowY: 'auto' }"
        >
          <v-treeview
            :style="{ userSelect: 'none' }"
            :items="items"
            activatable
            hoverable
            open-all
            transition
            rounded
            item-key="path"
            @update:active="escolhePagina"
          />
        </v-col>
        <v-col
          v-show="screen || $vssWidth > 960"
          sm="12"
          md="8"
          cols="12"
          :style="{ borderLeft: '1px solid #ccc', maxHeight: '100%' }"
          class="pa-3"
        >
          <v-row
            no-gutters
            :style="{ height: '90%', border: '1px solid var(--v-primary-lighten3)', borderRadius: '5px', maxHeight: '100%', overflowY: 'auto ' }"
            class="pa-3"
          >
            <component
              :is="component"
              :key="attComp"
              :title="titulo"
            />
          </v-row>
          <v-row
            :key="treeView"
            no-gutters
            :style="{ height: '10%' }"
            class="d-flex justify-end align-center"
          >
            <v-tooltip top>
              <template #activator="{attrs, on}">
                <v-btn
                  v-bind="attrs"
                  depressed
                  color="transparent"
                  class="btnEdit mr-2"
                  v-on="on"
                >
                  <v-icon
                    class="mr-2"
                    color="primary"
                  >
                    edit
                  </v-icon>
                  <span v-if="treeView || $vssWidth > 600">
                    Editar
                  </span>
                </v-btn>
              </template>
              <span>
                Editar
              </span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{attrs, on}">
                <v-btn
                  v-bind="attrs"
                  depressed
                  class="btnSave mr-2"
                  color="transparent"
                  v-on="on"
                >
                  <v-icon
                    class="mr-2"
                    color="green darken-1"
                  >
                    save
                  </v-icon>
                  <span v-if="treeView || $vssWidth > 600">
                    Salvar
                  </span>
                </v-btn>
              </template>
              <span>
                Salvar
              </span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{attrs, on}">
                <v-btn
                  v-bind="attrs"
                  depressed
                  class="btnCancel"
                  color="transparent"
                  v-on="on"
                >
                  <v-icon
                    class="mr-2"
                    color="error"
                  >
                    cancel
                  </v-icon>
                  <span v-if="treeView || $vssWidth > 600">
                    cancelar
                  </span>
                </v-btn>
              </template>
              <span>
                Cancelar
              </span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
  
<script>
export default {
  model: {
    prop: "geral",
    event: "update:geral",
  },
  props: {
    geral: {
      type: Boolean,
      required: true,
      default: false,
    },

  },
  data() {
    return {
      dialog: false,
      treeView: true,
      screen: false,
      component: null,
      titulo: '',
      attComp: 0,
      items: [
        {
          id: 1,
          name: 'Empresa',
          path: 'empresa',
          children: [
            { path: 'parametrosGerais/ConfigGerais', name: 'Configurações gerais' },
            { path: 'parametrosGerais/VendaDesconto', name: 'Venda/ Desconto/ Crediário' },
            { path: 'parametrosGerais/Comissao', name: 'Comissão' },
            { path: 'parametrosGerais/Produto', name: 'Produto' },
            { path: 'parametrosGerais/Transferencia', name: 'Transferência' },
            { path: 'parametrosGerais/Orcamento', name: 'Orçamento/ Consignação/ Pedido' },
            { path: 'parametrosGerais/ConfigEmail', name: 'Configuração de e-mail' },
            { path: 'parametrosGerais/CodGerado', name: 'Últimos códigos gerados' },
            { path: 'parametrosGerais/TabMotivos', name: 'Tabulação de motivos' },
          ],

        }, {
          id: 2,
          name: 'Integração SaaSFin',
          path: 'integracao',
          children: [
            { path: 'parametrosGerais/integracaoSaasfin/Parametros', name: 'Parâmetros' },
            { path: 'parametrosGerais/integracaoSaasfin/TransacaoPgto', name: 'Transações das formas de pgto.' },
            { path: 'parametrosGerais/integracaoSaasfin/TransacaoDespesa', name: 'Transações das despesas de caixa' },

          ],
        },
      ],

    }
  },
  watch: {
    geral(value) {
      this.dialog = value;

    },
  },
  methods: {
    escolhePagina(val) {
      const path = val[0]
      let tela = this.items[0].children.find(item => item.path == path);
      if (!tela) {
        tela = this.items[1].children.find(item => item.path == path);
       
      } else {
        this.titulo = tela.name;
        this.component = () => import(`./${path}.vue`);
        this.attComp++;
      }
      if (this.$vssWidth <= 960) {
        this.treeView = false;
        this.screen = true;
      }
      
    },
    backTreeView(){
      this.treeView = !this.treeView;
      this.screen = !this.screen
    },
  },
}
</script>
<style scoped>
.transicoes {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.btnEdit {
  border: 1px solid var(--v-primary-base) !important;
}

.btnEdit:hover {
  background: #f162368e !important;
  color: white;
}

.btnSave {
  border: 1px solid #43A047 !important;
}

.btnSave:hover {
  background: #43a0488b !important;
  color: white;
}

.btnCancel {
  border: 1px solid #E53935 !important;
}

.btnCancel:hover {
  background: #e5383587 !important;
  color: white;
}
</style>