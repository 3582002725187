<template>
  <v-dialog
    v-model="dialog"
    width="85%"
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template #activator="{ attrs, on }">
      <v-card
        v-bind="attrs"
        flat
        :style="{ backgroundColor: 'var(--v-primary-lighten1)', height: '100%' }"
        v-on="on"
      >
        <v-card-title class="pt-1 font-weight-bold">
          {{ dadosLojas.fantas }}
          <v-spacer />
          <span :style="{ fontSize: '16px' }">{{ dadosLojas.numLoja }}</span>
        </v-card-title>
      </v-card>
    </template>
    <v-card
      height="90vh"
      class="transicoes"
    >
      <v-row no-gutters>
        <v-toolbar
          class="primary white--text text-h5"
          flat
          dense
        >
          <v-tooltip>
            <template #activator="{ attrs, on }">
              <v-btn
                v-show="screen"
                icon
                dark
                v-bind="attrs"
                v-on="on"
                @click="backTreeView"
              >
                <v-icon>arrow_back</v-icon>
              </v-btn>
            </template>
            <span>
              Voltar
            </span>
          </v-tooltip>

          Parâmetros por loja
          <v-btn
            absolute
            right
            icon
            dark
            @click="paramLojas = !paramLojas"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-row>
      <v-row
        no-gutters
        :style="{ height: '92% !important' }"
        class="pl-1 pt-1 pr-1"
      >
        <v-col
          v-show="treeView || $vssWidth > 960"
          sm="12"
          md="4"
          cols="12"
          :style="{ maxHeight: '100%', overflowY: 'auto' }"
        >
          <v-treeview
            :style="{ userSelect: 'none' }"
            :items="items"
            activatable
            hoverable
            open-all
            transition
            rounded
            item-key="path"
            @update:active="escolhePagina"
          />
        </v-col>
        <v-col
          v-show="screen || $vssWidth > 960"
          sm="12"
          md="8"
          cols="12"
          :style="{ borderLeft: '1px solid #ccc', maxHeight: '100%' }"
          class="pa-3"
        >
          <v-row
            no-gutters
            :style="{ height: '90%', border: '1px solid var(--v-primary-lighten3)', borderRadius: '5px', maxHeight: '100%', overflowY: 'auto ' }"
            class="pa-3"
          >
            <component
              :is="component"
              :key="attComp"
              :title="titulo"
            />
          </v-row>
          <v-row
            :key="treeView"
            no-gutters
            :style="{ height: '10%' }"
            class="d-flex justify-end align-center"
          >
            <v-tooltip top>
              <template #activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  depressed
                  color="transparent"
                  class="btnEdit mr-2"
                  v-on="on"
                >
                  <v-icon
                    class="mr-2"
                    color="primary"
                  >
                    edit
                  </v-icon>
                  <span v-if="treeView || $vssWidth > 600">
                    Editar
                  </span>
                </v-btn>
              </template>
              <span>
                Editar
              </span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  depressed
                  class="btnSave mr-2"
                  color="transparent"
                  v-on="on"
                >
                  <v-icon
                    class="mr-2"
                    color="green darken-1"
                  >
                    save
                  </v-icon>
                  <span v-if="treeView || $vssWidth > 600">
                    Salvar
                  </span>
                </v-btn>
              </template>
              <span>
                Salvar
              </span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  depressed
                  class="btnCancel"
                  color="transparent"
                  v-on="on"
                >
                  <v-icon
                    class="mr-2"
                    color="error"
                  >
                    cancel
                  </v-icon>
                  <span v-if="treeView || $vssWidth > 600">
                    cancelar
                  </span>
                </v-btn>
              </template>
              <span>
                Cancelar
              </span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
  
<script>
export default {
  model: {
    prop: "geral",
    event: "update:geral",
  },
  props: {
    geral: {
      type: Boolean,
      required: true,
      default: false,
    },
    dadosLojas: {
      type: Object,
      required: true,
      default: () => { return {} },
    },

  },
  data() {
    return {
      dialog: false,
      treeView: true,
      screen: false,
      component: null,
      titulo: '',
      attComp: 0,
      items: [
        {
          id: 1,
          name: 'Dados gerais',
          // path: 'parametrosLoja/DadosGerais',
          path: 'parametrosLoja/pdv/OrdemServico',
        },
        {
          id: 2,
          name: 'Endereço',
          path: 'parametrosLoja/Endereco',
        },
        {
          id: 3,
          name: 'Parâmetros',
          path: 'parametros',
          children: [
            {
              id: 4,
              name: 'PDV',
              path: 'parametrosLoja/pdv/Venda',
              children: [
                { path: 'parametrosLoja/pdv/Venda', name: 'Venda' },
                { path: 'parametrosLoja/pdv/Pagamento', name: 'Pagamentos' },
                { path: 'parametrosLoja/pdv/Outros', name: 'Outros' },
              ],
            },
            { path: 'parametrosLoja/pdv/Caixa', name: 'Caixa' },
            { path: 'parametrosLoja/pdv/Produto', name: 'Produto' },
            { path: 'parametrosLoja/pdv/OrdemServico', name: 'Ordem de serviço' },
            { path: 'parametrosLoja/pdv/Impressao', name: 'Impressão' },
            {
              id: 5,
              name: 'Termo',
              path: 'parametrosLoja/termo/Garantia',
              children: [
                { path: 'parametrosLoja/termo/Geral', name: 'Geral' },
                { path: 'parametrosLoja/termo/Garantia', name: 'Garantia' },
              ],
            },
            {
              id: 6,
              name: 'Crediário',
              path: 'crediario',
              children: [
                { path: 'parametrosLoja/crediario/Configuracoes', name: 'Configurações' },
                { path: 'parametrosLoja/crediario/Contrato', name: 'Contrato' },
              ],
            },
            { path: 'parametrosLoja/crediario/Calendario', name: 'Calendário' },
            {
              id: 7,
              name: 'Integração',
              path: 'integracao',
              children: [
                { path: 'parametrosLoja/integracao/Tef', name: 'TEF' },
                { path: 'parametrosLoja/integracao/Correios', name: 'Correios' },
              ],
            },
            { path: 'parametrosLoja/AutorizarCrediario', name: 'Autorizar recebimento crediário' },
          ],
        },
      ],
    }
  },
  watch: {
    geral(value) {
      this.dialog = value;

    },
  },
  methods: {
    escolhePagina(val) {
      const path = val[0]
      let tela = this.items.find(item => item.path == path);
      if (!tela) {
        tela = this.items[2].children.find(item => item.path == path)
      }
      this.titulo = tela.name;
      this.component = () => import(`./${path}.vue`);
      this.attComp++;
      if (this.$vssWidth <= 960) {
        this.treeView = false;
        this.screen = true;
      }
      

    },
    backTreeView() {
      this.treeView = !this.treeView;
      this.screen = !this.screen
    },
  },
}
</script>
<style scoped>
.transicoes {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.btnEdit {
  border: 1px solid var(--v-primary-base) !important;
}

.btnEdit:hover {
  background: #f162368e !important;
  color: white;
}

.btnSave {
  border: 1px solid #43A047 !important;
}

.btnSave:hover {
  background: #43a0488b !important;
  color: white;
}

.btnCancel {
  border: 1px solid #E53935 !important;
}

.btnCancel:hover {
  background: #e5383587 !important;
  color: white;
}
</style>