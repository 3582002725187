
import ListaInst from "../../components/lojas/ListaInst.vue";
import ferramentas from "@/components/ferramentas";
import botoesAcao from "@/components/botoesAcao";
import moment from "moment";
//import axios from 'axios'
export default {
  components: { ferramentas, botoesAcao, ListaInst },
  data() {
    return {
      id:1,
      flexgrid: {},
      abaEscolhida: "",
      keyTabelaWijmo: 0,
      camposEditar: [],
      dados: [],
      abas: [],
      importsObrigatorios: ["COMISSÃO", "SALÁRIO FIXO", "VENDEDOR"],
      botoes: ["EXPORTAR_EXCEL", "EXPORTAR_PDF", "EDITAR", "SALVAR"],
      camposEditarProduto: [
        {
          DIVISION: "Dados gerais",
          fields: [
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: "CNPJ",
              hint: "", // opcional
              cols: 4,
              maxLength: 18,
              required: true,
              rules: [(n) => !!n || "Campo obrigatório"],
              chave: "ce045_nr_cnpj",
              mask: ["###.###.###-##", "##.###.###/####-##"],
            },
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: "Loja",
              hint: "", // opcional
              cols: 2,
              disabled: true,
              required: true,
              rules: [(n) => !!n || "Campo obrigatório"],
              chave: "ce045_nr_loja",
            },
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: "Nome fantasia",
              hint: "", // opcional
              cols: 6,
              required: true,
              maxLength: 100,
              rules: [(n) => !!n || "Campo obrigatório"],
              chave: "ce045_nm_fantas",
            },
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: "Matriz",
              hint: "", // opcional
              cols: 2,
              maxLength: 100,
              required: true,
              rules: [(n) => !!n || "Campo obrigatório"],
              chave: "ce045_nr_matriz",
            },
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: "Nome fantasia",
              hint: "", // opcional
              cols: 4,
              maxLength: 18,
              required: true,
              rules: [(n) => !!n || "Campo obrigatório"],
              chave: "ce045_nm_fantas",
            },
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: "Inscrição municipal",
              hint: "", // opcional
              cols: 3,
              maxLength: 14,
              required: true,
              rules: [(n) => !!n || "Campo obrigatório"],
              chave: "ce045_ds_insest",
            },
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: "Inscrição estadual",
              hint: "", // opcional
              cols: 3,
              maxLength: 14,
              chave: "ce045_ds_insmun",
            },
          ],
        },
        {
          DIVISION: "Endereço",
          fields: [
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: "CEP",
              hint: "", // opcional
              cols: 4,
              required: true,
              rules: [(n) => !!n || "Campo obrigatório"],
              chave: "ce045_nr_cep",
              maxLength: 10,
              mask: "##.###-###",
            },
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: "Número",
              hint: "", // opcional
              cols: 4,
              required: true,
              rules: [(n) => !!n || "Campo obrigatório"],
              chave: "ce045_nr_end",
              maxLength: 7,
            },
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: "IBGE",
              hint: "", // opcional
              cols: 4,
              required: true,
              rules: [(n) => !!n || "Campo obrigatório"],
              chave: "ce045_cd_ibge",
              maxLength: 14,
            },
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: "Endereço",
              hint: "", // opcional
              cols: 4,
              required: true,
              rules: [(n) => !!n || "Campo obrigatório"],
              chave: "ce045_nm_end",
              maxLength: 40,
            },
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: "Bairro",
              hint: "", // opcional
              cols: 4,
              required: true,
              rules: [(n) => !!n || "Campo obrigatório"],
              chave: "ce045_nm_bairro",
              maxLength: 30,
            },
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: "Cidade",
              hint: "", // opcional
              cols: 4,
              required: true,
              rules: [(n) => !!n || "Campo obrigatório"],
              chave: "ce045_nm_cidade",
              maxLength: 40,
            },
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: "Estado",
              hint: "", // opcional
              cols: 4,
              required: true,
              rules: [(n) => !!n || "Campo obrigatório"],
              chave: "ce045_sg_estado",
              maxLength: 2,
            },
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: "Complemento",
              hint: "", // opcional
              cols: 4,
              required: false,
              rules: [(n) => !!n || "Campo obrigatório"],
              chave: "ce045_ds_compl",
              maxLength: 20,
            },
            {
              model: "",
              vuetify: "textfield",
              type: "text", // principais: text, tel, password
              nome: "Telefone",
              hint: "", // opcional
              cols: 4,
              required: true,
              rules: [(n) => !!n || "Campo obrigatório"],
              chave: "ce045_nr_tel",
              maxLength: 13,
              mask: "(##) ####-####",
            },
          ],
        },
      ],
      opcoesTipo: [
        {
          value: "CE045_NM_RAZAO",
          text: "Razão social",
          type: ["text"],
        },
        {
          value: "CE045_NR_CNPJ",
          text: "CNPJ",
          type: ["text"],
          mask: "XX.XXX.XXX/XXXX-XX",
        },
        {
          value: "CE045_NR_LOJA",
          text: "Nr. loja",
          type: ["number"],
        },
        {
          value: "CE045_NR_CEP",
          text: "CEP",
          type: ["number"],
          mask: "XXXXX-XX",
        },
      ],
      itensFerramentas: [
        {
          text: "Situação",
          value: {
            type: "autocomplete",
            SP: false,
            opcoesTipo: "ce110_cd_situac",
            nome: "situacao",
            adicionar: false,
            filtro: false,
            itens: [
              { text: "Ativo", value: 1 },
              { text: "Bloqueado", value: 2 },
            ],
            objKey: false,
          },
        },
      ],
      colunasExcel: [
        {
          COMISSÃO: "",
          "SALÁRIO FIXO": "",
          VENDEDOR: "",
        },
      ],
      colunas: [
        {
          cabecalho: this.$t("ItensTabela.nrLoja"),
          larg: "1*",
          valor: "ce045_nr_loja",
          align: "right",
        },
        {
          cabecalho: "CNPJ",
          larg: "1*",
          valor: "ce045_nr_cnpj",
        },
        {
          cabecalho: this.$t("ItensTabela.razao"),
          larg: "1*",
          valor: "ce045_nm_razao",
          align: "right",
        },
        {
          cabecalho: this.$t("ItensTabela.nomeFantas"),
          larg: "1.6*",
          valor: "ce045_nm_fantas",
        },
        {
          cabecalho: this.$t("ItensTabela.situacao"),
          larg: "1.6*",
          valor: "ce045_cd_situac",
          align: "left",
        },
        {
          cabecalho: this.$t("ItensTabela.endereco"),
          larg: "1.6*",
          valor: "ce045_nm_end",
          align: "left",
        },
        {
          cabecalho: this.$t("ItensTabela.enderecoNr"),
          larg: "1.6*",
          valor: "ce045_nr_end",
          align: "left",
        },
        {
          cabecalho: this.$t("ItensTabela.cidade"),
          larg: "1.6*",
          valor: "ce045_nm_cidade",
          align: "left",
        },
        {
          cabecalho: this.$t("ItensTabela.estado"),
          larg: "1*",
          valor: "ce045_sg_estado",
          align: "left",
        },
        {
          cabecalho: this.$t("ItensTabela.cep"),
          larg: "1.6*",
          valor: "ce045_nr_cep",
          align: "left",
        },
      ],
    };
  },
  computed: {
    camposCEP() {
      return this.camposEditarProduto[1].fields[0];
    },
  },
  watch: {
    camposCEP: {
      deep: true,
      handler() {
        if (this.camposEditarProduto[1].fields[0].model.length === 10) {
          const CEP = this.camposEditarProduto[1].fields[0].model
            .replace(".", "")
            .replace("-", "");
          /* axios.get(`https://brasilapi.com.br/api/cep/v1/${CEP}`)
              .then(respostaCep => {
                this.camposEditarProduto[1].fields[3].model = respostaCep.data.street
                this.camposEditarProduto[1].fields[4].model = respostaCep.data.neighborhood
                this.camposEditarProduto[1].fields[5].model = respostaCep.data.city
                this.camposEditarProduto[1].fields[6].model = respostaCep.data.state
              }) */
        }
      },
    },
  },
  mounted() {},
  methods: {
    /* eslint-disable */
    addNovoItem(item) {
      //
    },
    /* eslint-disable */
    initializeGrid(flexgrid) {
      this.flexgrid = flexgrid;
    },
    escolheAba(aba) {
      this.abaEscolhida = aba;
    },
    alteraVendFerramentas(ret) {
      this.dados.forEach((f) => {
        if (f.isSelected) {
          f.ce045_cd_situac = ret.situacao == 1 ? "Ativo" : "Bloqueado";
        }
      });
      this.keyTabelaWijmo++;
    },
    async mountObj(obj) {
      this.colunas.reverse();
      try {
        this.startLoader();
        let lojasDB = await this.chamaMulti("SP_CE360905001", obj);
        lojasDB = lojasDB.database;
        this.getLojas(lojasDB);
      } catch {
        this.notification().error({erro: "Erro ao trazer o produto"});
      } finally {
        this.stopLoader();
      }
    },
    getLojas(lojasDB) {
      this.dados = lojasDB.map((p, i) => {
        return {
          ...p,
          ce045_cd_situac: p.ce045_cd_situac == 1 ? "Ativo" : "Inativo",
          ce045_nr_cep: this.maskCep(p.ce045_nr_cep),
          ce045_nr_cnpj: this.maskCPFCNPJ(p.ce045_nr_cnpj),
          ce045_ds_compl: p.ce045_ds_compl ? p.ce045_ds_compl : "",
          isSelected: false,
          itemKey: i,
        };
      });
      const objAba = {
        nome_aba: `${this.$t("TelaLojas.pesquisa")} ${this.abas.length +
          1} - ${moment()
          .locale("pt-br")
          .format("l")} - ${this.$t("TelaLojas.quantidade")}: ${
          this.dados.length
        }`,
        pesquisa: lojasDB,
        remove: false,
      };
      this.abas.push(objAba);
    },
    erroBuscaPorFiltro() {
      this.notification().error({erro: "O filtro possui algum erro."});
    },
    async editNovoItem(e) {
      let usuario = await this.getUser();
      let ent = {
        _rNR_CNPJ: this.unmask(e.ce045_nr_cnpj),
        _rNR_LOJA: e.ce045_nr_loja,
        _rNM_FANTAS: e.ce045_nm_fantas.toUpperCase(),
        _rNR_MATRIZ: e.ce045_nr_matriz,
        _rDS_INSMUN: e.ce045_ds_insmun,
        _rDS_INSEST: e.ce045_ds_insest,
        _rNR_CEP: this.unmask(e.ce045_nr_cep),
        _rNR_END: e.ce045_nr_end.toUpperCase(),
        _rNM_END: e.ce045_nm_end.toUpperCase(),
        _rNM_CIDADE: e.ce045_nm_cidade.toUpperCase(),
        _rNM_BAIRRO: e.ce045_nm_bairro.toUpperCase(),
        _rSG_ESTADO: e.ce045_sg_estado.toUpperCase(),
        _rDS_COMPL: e.ce045_ds_compl.toUpperCase(),
        _rCD_OPESIS: usuario.base.us01_nr_usr,
      };
      try {
        this.startLoader();
        await this.request({
          metodo: "get",
          sp: "SP_CE360905002",
          params: ent,
        });
        this.notification().success({mensagem:"Informações salvas com sucesso!"});
      } catch {
        this.notification().error({erro: "Erro ao editar as informações"});
      } finally {
        this.stopLoader();
      }
    },
    salvaDadosTabela() {
      //
    },
    /* eslint-disable */
    dadosConfirmados(dadosCorretos, dadosIncorretos, nomeArquivo) {},
    /* eslint-disable */
  },
};
